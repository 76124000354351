<template>
    <el-dialog :title="title" :close-on-click-modal="false" :fullscreen="true" append-to-body :visible.sync="visible"
               class="collectForm dialogTable" @close="closeForm()">
        <div v-loading="loading" class="formBox">
            <el-form size="small" :inline="true" :model="inputForm" ref="inputForm"
                     label-width="180px" :disabled="method==='view'" class="recordCollect">
                <el-form-item label="借阅单号：" prop="borrowCodeNumber">
                    <el-input v-model.trim="inputForm.borrowCodeNumber"></el-input>
                </el-form-item>
                <el-form-item label="借阅人姓名：" prop="borrowUserIdName">
                    <el-input v-model.trim="inputForm.borrowUserIdName"></el-input>
                </el-form-item>
                <el-form-item label="手机：" prop="telPhone">
                    <el-input v-model.trim="inputForm.telPhone"></el-input>
                </el-form-item>
                <el-form-item label="借阅人单位：" prop="unit">
                    <el-input v-model.trim="inputForm.unit"></el-input>
                </el-form-item>
                <el-form-item label="借阅时间：" prop="borrowTime">
                    <el-date-picker class="w100i"
                                    v-model="inputForm.borrowTime" value-format="yyyy-MM-dd"
                                    format="yyyy-MM-dd"
                                    type="date" clearable placeholder="请选择">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="期限：" prop="deadline">
                    <el-date-picker class="w100i"
                                    v-model="inputForm.deadline" value-format="yyyy-MM-dd"
                                    format="yyyy-MM-dd"
                                    type="date" clearable placeholder="请选择">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="借阅目的：" prop="borrowPurpose">
                    <el-select class="w100i" v-model="inputForm.borrowPurpose"
                               clearable placeholder="请选择">
                        <el-option
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                                v-for="item in $dictUtils.getDictList('borrow_purpose')">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="借阅经办人：" prop="lendUserName" v-if="method == 'view'">
                    <el-input v-model.trim="inputForm.lendUserName" disabled></el-input>
                </el-form-item>
                <el-form-item label="经办时间：" prop="lendTime" v-if="method == 'view'">
                    <el-input v-model.trim="inputForm.lendTime" disabled></el-input>
                </el-form-item>
                <el-form-item label="审核状态：" prop="auditState" v-if="method != 'add'">
                    <el-select class="w100i" v-model="inputForm.auditState" disabled>
                        <el-option
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                                v-for="item in $dictUtils.getDictList('audit_state')">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="审核人：" prop="auditUserIdName" v-if="method != 'add'">
                    <el-input v-model.trim="inputForm.auditUserIdName" disabled></el-input>
                </el-form-item>
                <el-form-item label="审核时间：" prop="auditTime" v-if="method != 'add'">
                    <el-input v-model.trim="inputForm.auditTime" disabled></el-input>
                </el-form-item>
                <el-form-item label="反馈详情：" prop="feedback" v-if="method != 'add'">
                    <el-input v-model.trim="inputForm.feedback" disabled></el-input>
                </el-form-item>
                <el-form-item label="备注：" prop="remark" class="rowBox">
                    <el-input
                            maxlength="200"
                            type="textarea"
                            :rows="2"
                            placeholder="请输入备注信息。（限200字）"
                            v-model="inputForm.remark">
                    </el-input>
                </el-form-item>
                <el-descriptions title="借阅档案信息"></el-descriptions>
                <el-form-item label="档案：" prop="list" class="rowBox">
                    <el-button size="small" type="primary" @click="selRecord()" v-if="method != 'view'">请选择</el-button>
                    <el-table :data="inputForm.list" size="small" max-height="300" class="table m_t1"
                              v-if="inputForm.list.length">
                        <el-table-column prop="name" label="借阅卷宗" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <div>{{ scope.row.name}}</div>
                            </template>
                        </el-table-column>
                        <el-table-column fixed="right" width="180" label="操作">
                            <template slot-scope="scope">
                                <el-button type="text" size="small" @click="view(scope.row, 'view')" :disabled="false">
                                    详情
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
            </el-form>
        </div>
        <div class="text_center p_t2">
            <el-button size="small" @click="visible = false">关闭</el-button>
            <el-button size="small" type="primary" @click="lastOrNext(0)"
                       v-noMoreClick>上一条
            </el-button>
            <el-button size="small" type="primary" @click="lastOrNext(1)"
                       v-noMoreClick>下一条
            </el-button>
            <el-button size="small" type="primary" @click="lend()"
                       v-if="inputForm.lendState == '1'" v-noMoreClick>登记
            </el-button>
        </div>
        <RecordDetails ref="recordDetails"></RecordDetails>
    </el-dialog>
</template>

<script>
    import RecordDetails from '../../components/recordDetails'

    export default {
        components: {RecordDetails},
        data() {
            return {
                title: '',
                visible: false,
                method: '',
                loading: false,
                inputForm: {
                    id: '',
                    borrowCodeNumber: '',
                    borrowUserIdName: '',
                    telPhone: '',
                    unit: '',
                    unitId: '',
                    borrowTime: '',
                    deadline: '',
                    borrowPurpose: '',
                    lendState: '',
                    remark: '',
                    list: [],
                },
                listSearchForm: [],
            }
        },
        methods: {
            init(row, method, searchForm) {
                this.listSearchForm = searchForm
                if (row) {
                    this.inputForm.id = row.id
                }
                this.loading = false
                this.method = method
                if (method === 'view') {
                    this.title = '查看借阅申请'
                }
                this.visible = true
                this.$nextTick(() => {
                    this.$refs['inputForm'].resetFields()
                    if (method !== 'add') {
                        this.getInfo()
                    }
                })
            },
            // 获取详情
            getInfo() {
                this.loading = true
                this.$axios(this.api.record.entityDetail + '/' + this.inputForm.id).then((res) => {
                    if (res.status) {
                        this.inputForm = this.recover(this.inputForm, res.data)
                    }
                    this.loading = false
                })
            },
            // type 0上一条, 1下一条
            lastOrNext(type) {
                this.loading = true
                this.listSearchForm.indexType = type
                this.$axios(this.api.record.lendListLastOrNext, this.listSearchForm, 'post').then((res) => {
                    if (res.status && res.data.index) {
                        this.listSearchForm.index = res.data.index
                        this.inputForm = this.recover(this.inputForm, res.data)
                    } else {
                        this.$message.error(`已是${type == 0 ? '第' : '最后'}一条`);
                    }
                    this.loading = false
                })
            },
            // 登记
            lend() {
                this.$confirm(`确定登记？确定后表示借阅人已拿到本次申请的内部利用全部实体档案`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios(this.api.record.entityLends, [this.inputForm.id], 'post').then(data => {
                        if (data.status) {
                            this.$message.success('登记成功')
                            this.getInfo()
                        } else {
                            this.$message.success('登记失败')
                        }
                    })
                })
            },
            // 查看
            view(row, method) {
                if (row.archivesDelFag == 1) {
                    this.$message.error('该档案已删除！')
                    return
                }
                this.$refs.recordDetails.init(row, method, row.archivesBasicDataId, 'archivesDataId')
            },
            // 关闭
            closeForm() {
                this.visible = false
                this.$emit('refreshDataList')
            },
        },
    }
</script>

<style scoped>

</style>
